
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container, Row, Form, Button, Spinner, Table } from 'react-bootstrap';
import { formatToAUD } from '../utils';


export default function Transactions(props) {

  const [transactions, setTransactions] = useState();

  const getTransactionsData = (transactions) => {
    console.log(transactions);
    let transactionData = [];
    for (const transaction of transactions) {
      let invoiceNumber = transaction.billInfo?.invoiceNumber;
      if (transaction.nb_billInfo) invoiceNumber = transaction.nb_billInfo.billNumber;

      if (!invoiceNumber) invoiceNumber = "N/A";

      transactionData.push(<tr>
        <td>{transaction.source[0]?.abnInfo?.legalName}</td>
        <td>{invoiceNumber}</td>
        <td>{formatToAUD(transaction.chargeTotal)}</td>
        <td>{formatToAUD(transaction.schedule.chargeAmount)}</td>
        <td>{moment(transaction.schedule.chargeOnDate).format("YYYY-MM-DD")}</td>
        <td>{formatToAUD(transaction.schedule.lateFee || 0)}</td>
        <td>{transaction.schedule.previousChargeOnDates?.length || 0}</td>
        <td>{transaction.schedule.chargeCompleted}</td>
      </tr>);
    }
    return transactionData;
  }

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_LUCA_PAY}/lucapay/admin/v1/ongoingInstalments`,
      method: "GET",
      headers: {
        Authorization: `bearer ${props.user.accessToken}`
      }
    }).then((response) => {
      setTransactions(response.data.data);
    }).catch((err) => { alert("Unable to get Transactions!"); console.log(err) });
  }, []);

  return (
    <Container className='mt-5'>
      <Row>
        <h1>LUCA Pay ongoing Transactions - { moment().format("YYYY-MM-DD") }</h1>
      </Row>
      <Row className='mt-5'>
        <Table responsive="xl">
          <thead><tr><th>User</th><th>Invoice</th><th>Amount</th><th>Instalment</th><th>Charge on Date</th><th>Late Fee</th><th>Defaulted</th><th>Status</th></tr></thead>
          <tbody>
            {transactions && getTransactionsData(transactions)}
          </tbody>
        </Table>
      </Row>

    </Container>
  );
}