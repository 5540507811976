
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Container, Form, Row, Table, Spinner } from 'react-bootstrap';

export default function OCRApp() {

    const [file, setFile] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [loading, setLoading] = useState(false);

    function handleChange(event) {
        setFile(event.target.files[0]);
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (!file) return;
        const url = 'https://ocrapp.gentledune-2b81604d.australiaeast.azurecontainerapps.io/uploadfile/';
        const formData = new FormData();
        formData.append('file', file, file.name);
        setLoading(true);

        axios({
            url,
            method: "POST",
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
            },
        }).then((response) => {
            setFile(null);
            setLoading(false);
            setInvoiceData(response.data);
            console.log(response.data);
        });

    }

    function getTableData(res) {

        let tableData = [];

        for (const field in res) {

            if (["AmountDue", "LineItems", "SubTotal", "TotalTax", "CustomerAddress", "InvoiceTotal", "VendorAddress", "BillingAddress"]
                .find(ff => ff === field)) continue;
            tableData.push(<tr>
                <td>{field}</td>
                <td>{res[field][0]}</td>
                <td>{Math.round(res[field][1] * 100)}%</td>
            </tr>);
        }

        for (const field of ["AmountDue", "SubTotal", "TotalTax", "InvoiceTotal"]) {
            const { amount, accuracy } = getAmountAndAccuracy(res, field);
            tableData.push(<tr><td>{field}</td><td>{amount}</td><td>{accuracy}%</td></tr>);            
        }

        return tableData;
    }

    function getAmountAndAccuracy(data, fieldName) {
        if (data[fieldName] && data[fieldName][0]) {
            return { amount: data[fieldName][0].amount, accuracy: Math.round(data[fieldName][1] * 100) }
        }

        return { amount: 0, accuracy: 0 };
    }

    return (
        <Container className='mt-5'>
            <Row>
                <Form onSubmit={handleSubmit}>
                    <h1>Upload the Invoice</h1>
                    <Form.Group controlId="formFile" className="mb-3" onChange={handleChange}>
                        <Form.Control type="file" />
                    </Form.Group>

                    <Button variant="primary" type="submit">Upload</Button> { loading && <Spinner animation="border" variant="primary" size='sm'/>}
                </Form>
            </Row>
            <Row className='mt-5'>
                <Table responsive="xl">
                    <thead><tr><th>Field</th><th>Value</th><th>Accuracy</th></tr></thead>
                    <tbody>
                        {invoiceData && getTableData(invoiceData.concise_result)}
                    </tbody>
                </Table>


            </Row>

        </Container>
    );
}


