import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { SignIn, useAuth } from "./components/Auth";
import Header from './components/Header';
import Home from './components/Home';
import OCRApp from './components/OcrApp';
import Transactions from './components/Transactions';


function App() {

  const { user, loading, error } = useAuth();

  return (
    user ?
      <>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={
              <>
                <Header user={user} loading={loading} error={error} />
                <Home user={user} loading={loading} error={error} />
              </>
            } />
            <Route path="/ocrapp" element={
              <>
                <Header user={user} loading={loading} error={error} />
                <OCRApp />
              </>} />

            <Route path="/LPTransactions" element={
              <>
                <Header user={user} loading={loading} error={error} />
                <Transactions user={user} />
              </>
            } />

          </Routes>
        </BrowserRouter >

      </> :
      !loading &&
      <Container>
        <Row md={4} className="mt-5 justify-content-center">
          <Col><SignIn /></Col>
        </Row>
      </Container>
  );
}

export default App;
