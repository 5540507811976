
import { useRef } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";

export default function AssignCouponCodeModal(props) {

  const couponRef = useRef("");

  console.log(props.tenant?.current.id);

  const closeConfirm = () => {
    props.closeCouponModal(false);
  }

  const assignCouponCode = (e) => {
    console.log(couponRef.current.value);
    props.assignCouponCode(props.tenant?.current.id, couponRef.current.value);
  }

  return <Modal show={props.showCouponModal} onHide={closeConfirm}>
    <Modal.Header closeButton>
      <Modal.Title>Assign Coupon Code</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Row className="align-items-center px-4 py-2">
          <Col>{props.tenant?.current.name}</Col>
          <Col><Form.Control type="text" placeholder="Coupon Code" id="couponCode" ref={couponRef}/></Col>
        </Row>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={closeConfirm}>
        Close
      </Button>
      <Button variant="primary" onClick={assignCouponCode}>
        Assign
      </Button>
    </Modal.Footer>
  </Modal>
}