
import { Row, Col, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function ReferralModal(props) {

  const referrals = props.tenant?.current.referrals?.data;

  const closeConfirm = () => {
    props.closeReferralModal(false);
  }

  return <Modal show={props.showReferralModal} onHide={closeConfirm} size="lg" scrollable={true}>
    <Modal.Header closeButton>
      <Modal.Title>Referrals - {(referrals?.totalRecords || 0)}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="{ }">
      {referrals?.referees?.map((referral, index) => {
        return <Row className="px-4 py-2">
          <Col xs={4}>{referral.legalName || ""}</Col>
          <Col xs={6}>{referral.email}</Col>
          <Col xs={1}>
            { referral.inLuca ? <FontAwesomeIcon icon={solid('check')} size="1x" className="button-icon" title="In LUCA Plus" /> :
            <FontAwesomeIcon icon={solid('circle')} size="1x" className="button-disabled-icon" title="Haven't signed up" />}
          </Col>
          <Col xs={1}>
            { referral.eligible ? <FontAwesomeIcon icon={solid('check')} size="1x" className="button-icon" title="Signed up for LUCA Pay" /> :
            <FontAwesomeIcon icon={solid('circle')} size="1x" className="button-disabled-icon" title="Haven't registered for LUCA Pay" /> }
          </Col>
        </Row>
      })}

    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={closeConfirm}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
}