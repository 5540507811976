import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { SignOut } from "./Auth";

export default function Header(props) {

  const user = props.user;

  return <Container>
    <Navbar expand="lg">
      <Navbar.Brand href="#">LUCA+ Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          navbarScroll
        >
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/ocrapp">OCR</Nav.Link>
          <Nav.Link href="/LPTransactions">LUCA Pay Transactions</Nav.Link>
        </Nav>

      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end px-2">
        <Navbar.Text>
          Hi {user.displayName}!
        </Navbar.Text>
        <Navbar.Text className="mx-2">
          <SignOut />
        </Navbar.Text>

      </Navbar.Collapse>

    </Navbar>
  </Container>
}