
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { useAuthState } from "react-firebase-hooks/auth";

firebase.initializeApp({
  apiKey: "AIzaSyA830w-PmkB7SgOCcqnbUV2kUVzIUJphvg",
  authDomain: "lucaplus-mobile.firebaseapp.com",
  projectId: "lucaplus-mobile",
  storageBucket: "lucaplus-mobile.appspot.com",
  messagingSenderId: "54698609126",
  appId: "1:54698609126:web:e91fab16f3a64eb11cee27",
  measurementId: "G-YWFER5J846"
});

const auth = firebase.auth();

export function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };
  return (
    <div>
      <button onClick={signInWithGoogle} className="login-with-google-btn">Sign in with google</button>
    </div>
  );
}

export function useAuth() {
  const [user, loading, error] = useAuthState(auth);

  return { user, loading, error };
}

export function SignOut() {
  return (
    <button className="login-with-google-btn"
      onClick={() => {
        auth.signOut();
      }}
    >
      Sign out
    </button>
  );
}


